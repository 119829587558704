import React, { Dispatch, SetStateAction } from 'react';

import express from 'express';
import { Document } from 'prismic-javascript/types/documents';

import { AdvantagePricesType } from '../components/AdPreviewSearchPage/AdPreviewSearchPage.types';

import {
  BER_RATING,
  Paging,
  SearchRequest,
} from '@dist-property-frontend/types';

export enum AdDimensions {
  MPU_WIDTH = '300px',
  MPU_HEIGHT = '250px',
  SKYSCRAPER_WIDTH = '160px',
  SKYSCRAPER_HEIGHT = '600px',
  LEADERBOARD_WIDTH = '720px',
  LEADERBOARD_HEIGHT = '90px',
  STANDARD_BANNER_WIDTH = '468px',
  STANDARD_BANNER_HEIGHT = '60px',
  TILE_WIDTH = '168px',
  TILE_HEIGHT = '60px',
}

export enum PropertyCategories {
  SALES = 'SALES',
  SHARING = 'SHARING',
  NEW_DEVELOPMENT = 'NEW_DEVELOPMENT',
  COMMERCIAL_SALES = 'COMMERCIAL_SALES',
  COMMERCIAL_RENT = 'COMMERCIAL_RENT',
  NEW_HOMES = 'New Homes',
  BUY = 'Buy',
  RENT = 'Rent',
  SOLD = 'Sold',
}

export enum CSSTransitionType {
  EASE = 'ease',
  LINEAR = 'linear',
  EASEIN = 'ease-in',
  EASEOUT = 'ease-out',
  EASEINOUT = 'ease-in-out',
}

export type KeyNameItem = {
  key: string;
  name: string;
};

export type Photo = {
  size1440x960?: string;
  size1200x1200?: string;
  size720x480?: string;
  size600x600?: string;
  size680x392?: string;
  size400x300?: string;
  size360x240?: string;
  size320x280?: string;
  size300x200?: string;
  size72x52?: string;
};

export interface CaptionedPhoto extends Photo {
  caption: string;
}

export type Video = {
  url: string;
  embedUrl: string;
  externalId: string;
};

export type VirtualTour = {
  externalVirtualTourId: string;
  externalVirtualTourUrl: string;
  thumbnailUrl: string;
  provider: string;
};

export type Media = {
  images?: Photo[];
  videos?: Video[];
  virtualTour?: VirtualTour[];
  totalImages: number;
  hasVideo: boolean;
  hasVirtualTour: boolean;
  hasBrochure?: boolean;
  brochure?: { [key: string]: string }[];
};

export type ImageList = {
  images: Photo[];
  totalImages: number;
};

export type BER = {
  rating: BER_RATING;
  code?: string;
  epi?: string;
  yearConstructed?: number;
};

export type OpenViewing = {
  day: string;
  startTime: string;
  endTime: string;
};

export type OpenViewings = {
  summary: string;
  details?: OpenViewing[];
};

export type PageBranding = {
  standardLogo?: string;
  squareLogo?: string;
  squareLogos?: string[];
  backgroundColour?: string;
  rectangleLogo?: string;
};

interface BasePropertyCard {
  id: number;
  price: string;
  title: string;
  seoTitle: string;
  media: Media;
  numBedrooms?: string;
  numBathrooms?: string;
  bathroomType?: string;
  propertyTypeExtraInfo?: string;
  ber?: BER;
  propertyType?: string;
  propertySize?: string;
  seoFriendlyPath: string;
  /**
   * Our default is PROPERTY, this field if returned, allows
   * us to know if the item is a Development for example
   */
  type?: string;
}

export interface PropertyCardSubUnit {
  id: number;
  price?: string;
  media: Media;
  numBedrooms?: string;
  numBathrooms?: string;
  bathroomType?: string;
  category?: string;
  ber?: BER;
  propertyType?: string;
  seoFriendlyPath?: string;
}

export interface PropertyCard extends BasePropertyCard {
  openViewings?: OpenViewings;
  sticker?: string;
  featuredLevel?: AdFeatureLevel;
  featuredLevelFull?: AdFeatureLevelFull;
  featuredLevelText?: AdFeatureLevel;
  showFeaturedLevelLabel?: boolean;
  seller?: Seller;
  isShowcase?: boolean;
  loadEagerly?: boolean;
  newHome?: SubUnit;
  prs?: SubUnit;
  parentDevelopment?: any;
  pageBranding?: PageBranding;
  priceHistory?: PriceHistoryIndicator;
  label?: string;
  CDN_URL_STATIC_DIRECTORY: string;
  section?: string;
  showSaveButton?: boolean;
  showAlertButton?: boolean;
  isSaved?: boolean;
  isAlertEnabled?: boolean;
  onSaveButtonClick?(
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  onAlertButtonClick?(
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void;
  disabled?: boolean;
  labelText: string;
  labelType: 'primary' | 'secondary' | 'alert' | 'success' | 'disabled';
  labelSize: 'small' | 'large';
  testId?: string;
  point: Point;
  abbreviatedPrice?: string;
  cardSize?: 'SMALL';
  propertySize?: string;
  premierPartner?: boolean;
  offers?: OffersType;
  customBlock?: CustomBlock;
  actionButtons?: JSX.Element | null;
  category?: string;
  platform?: string;
  paymentText?: PaymentText;
  parkingCustomBlock?: ParkingCustomBlock;
  noLink?: boolean;
}

export interface AdPreviewPropertyCard extends PropertyCard {
  featuredLevelFull: AdFeatureLevelFull;
  ranking: Ranking;
  products: AdvantagePricesType[];
}

export interface OffersType {
  bookingDeposit: number;
  minimumIncrement: number;
  minimumOfferAmount: number;
  highestOffer: number;
  offersCount: number;
  awaitingBidders?: 1 | 0;
  status?: 'OPENED' | 'CLOSED' | 'CANCELLED';
}

export interface BaseVTPropertyCard {
  id: number | string;
  title?: string;
  propertyType?: string;
  propertySize?: string;
  spId?: string;
  adState?: 'DELETED' | 'PUBLISHED';
  media?: any;
  route?: any;
  size?: any;
  imgSrc?: string;
  ber?: BER;
  rating?: string;
  notFullMarketValue?: boolean;
  price?: string;
  offers?: OffersType;
}

export interface AgentOffersPropertyCard {
  id: string;
  imgSrc: string;
  title: string;
  propertyType: string;
  offers?: OffersType;
  route: any;
  statusLabel?: boolean;
  listing: {
    statusLabel?: boolean;
    highestOffer: number | string | boolean;
    offersCount?: number;
    state: string;
  } & BaseVTPropertyCard;
}

export interface VT_PPRPropertyCard extends BaseVTPropertyCard {
  ber?: BER;
  dateSold?: string;
  listedPrice?: string;
  price?: string;
  numBedrooms?: string;
  numBathrooms?: string;
  yearBuilt?: string;
  notFullMarketValue?: boolean;
}

export interface VT_ResidentialSalesPropertyCard extends BaseVTPropertyCard {
  ber?: BER;
  dateSold?: string;
  price?: string;
  listedPrice?: string;
  numBedrooms?: string;
  numBathrooms?: string;
  dateListed?: string;
  notFullMarketValue?: boolean;
}

export interface VT_ResidentialRentPropertyCard extends BaseVTPropertyCard {
  ber?: BER;
  numBedrooms?: string;
  numBathrooms?: string;
  dateListed?: string;
  listedRentPrice?: string;
  dateRemoved?: string;
}

export interface VT_CommercialPropertyCard extends BaseVTPropertyCard {
  dateListed?: string;
  listedSalePrice?: string;
  listedLeasePrice?: string;
  dateRemoved?: string;
}

export type VTPropertyCard =
  | VT_PPRPropertyCard
  | VT_ResidentialSalesPropertyCard
  | VT_ResidentialRentPropertyCard
  | VT_CommercialPropertyCard
  | AgentOffersPropertyCard;

export type PriceHistoryIndicator = {
  direction: PriceHistoryIndicatorDirection;
  priceDifference: string;
}[];

export enum PriceHistoryIndicatorDirection {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export enum ImageSizes {
  size1440x960 = 'size1440x960',
  size1200x1200 = 'size1200x1200',
  size720x480 = 'size720x480',
  size680x392 = 'size680x392',
  size600x450 = 'size600x450',
  size400x300 = 'size400x300',
  size360x240 = 'size360x240',
  size320x280 = 'size320x280',
  size300x200 = 'size300x200',
}

/**
 * ImageSizes enum includes other image sizes that are not sent by the search API
 * These are the sizes sent by the search API for listings images (SRP)
 */
export type ListingsImageSize =
  | ImageSizes.size720x480
  | ImageSizes.size400x300
  | ImageSizes.size360x240
  | ImageSizes.size300x200
  | ImageSizes.size320x280
  | ImageSizes.size680x392
  | 'size72x52'
  | 'size600x600';

export enum AdFeatureLevel {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  LITE = 'LITE',
  PREMIUM = 'PREMIUM',
  FEATURED = 'FEATURED',
  SPOTLIGHT = 'SPOTLIGHT',
}

export enum AdFeatureLevelFull {
  STANDARD = 'STANDARD',
  FEATURED = 'FEATURED',
  PREMIUM = 'PREMIUM',
  PREMIER_PARTNER_LITE = 'PREMIER_PARTNER_LITE',
  PREMIER_PARTNER_PREMIUM = 'PREMIER_PARTNER_PREMIUM',
  PREMIER_PARTNER_FEATURED = 'PREMIER_PARTNER_FEATURED',
}

export enum FeaturedLevelFullToRanking {
  STANDARD = 'standard',
  FEATURED = 'featured',
  PREMIUM = 'premium',
  PREMIER_PARTNER_LITE = 'advantageLite',
  PREMIER_PARTNER_PREMIUM = 'advantagePremium',
  PREMIER_PARTNER_FEATURED = 'advantageFeatured',
}

type RankingKeysType =
  | 'standard'
  | 'featured'
  | 'advantageLite'
  | 'advantagePremium'
  | 'advantageFeatured';

export type Ranking = {
  [K in RankingKeysType]: string;
};

export type SoldPropertyInfo = {
  ber?: BER;
  dateListed?: string;
  dateRemoved?: string;
  dateSold?: string;
  sellerName?: string;
  panelTitle?: string;
  partialMarketValue?: boolean;
  CDN_URL_STATIC_DIRECTORY: string;
  seoFriendlyPath?: string;
};

export type RentCommercialPropertyInfo = {
  ber?: BER;
  listPrice?: string;
  dateListed?: string;
  dateRemoved?: string;
  sellerName?: string;
  panelTitle?: string;
  CDN_URL_STATIC_DIRECTORY: string;
  seoFriendlyPath?: string;
};

export type ProperyInfo = SoldPropertyInfo & RentCommercialPropertyInfo;

export type SubUnit = {
  totalUnitTypes: number;
  tagLine?: string;
  subUnits?: PropertyCardSubUnit[];
  parentDevelopment?: any;
  location?: string;
  about?: string;
  sitePlan?: string;
  floorPlan?: string;
  brochure?: string;
  developmentName?: string;
  unitsAvailable?: number;
  unitName?: string;
  unitStatus: UnitStatus;
  amenities?: CaptionedPhoto[];
  allowEnquiriesOnParent?: boolean;
};
/**
 * BE model
 *  @ApiModelProperty(value = "The listing")
 *  private DaftAdSearch listing;
 */
export type Listing = {
  id: number;
  title: string;
  seoTitle: string;
  sections?: string[];
  saleType?: string[];
  featuredLevel?: AdFeatureLevel;
  featuredLevelFull?: AdFeatureLevelFull;
  sticker?: string;
  label?: string;
  publishDate?: number;
  price: string;
  soldPrice?: string;
  abbreviatedPrice?: string;
  numBedrooms?: string;
  numBathrooms?: string;
  bathroomType?: string;
  propertyType?: string;
  propertyTypeExtraInfo?: string;
  daftShortcode?: string;
  seller?: Seller;
  dateOfConstruction?: string | number;
  priceHistory?: PriceHistoryIndicator;
  media: Media;
  ber?: BER;
  platform?: string;
  floorArea?: any; //  @Deprecated on BE
  propertySize?: string;
  openViewings?: OpenViewings;
  point: Point;
  seoFriendlyPath: string;
  newHome?: SubUnit;
  prs?: SubUnit;
  pageBranding?: PageBranding;
  category?: string;
  state?: string;
  offers?: OffersType;
  premierPartner?: boolean;
  soldContent?: SoldSectionAdVariant;
  soldDate?: string;
};
export type Property = {
  auctionInfo?: {
    date: Date;
    address: string;
  };
  description?: string;
  facilities?: {
    key: string;
    name: string;
  }[];
  featuredLevel?: AdFeatureLevel;
  propertySize?: string;
  id: number;
  media: Media;
  numBedrooms?: string;
  numBathrooms?: string;
  bathroomType?: string;
  openViewings?: OpenViewings;
  price: string;
  abbreviatedPrice: string;
  priceHistory?: PriceHistoryIndicator;
  propertyTypeExtraInfo?: string;
  propertyOverview?: { label: string; text: string }[];
  sellingType?: string;
  newHome?: SubUnit;
  prs?: SubUnit;
  propertyType?: string;
  taxDesignation?: string;
  title: string;
  seoTitle: string;
  ber?: BER;
  features?: string[];
  lastUpdateDate?: string;
  seller?: Seller;
  point: Point;
  seoFriendlyPath: string;
  note?: string;
  pageBranding?: PageBranding;
  category?: string;
  state?: string;
  label?: string;
  section?: string;
  daftShortcode?: string;
  offers?: OffersType;
  premierPartner?: boolean;
  nonFormatted?: { beds: number; price: number; section: string };
  platform?: string;
  primaryAreaId?: number;
  isInRepublicOfIreland?: boolean;
  dateOfConstruction?: string | number;
  featuredLevelFull?: AdFeatureLevelFull;
  sticker?: string;
  soldContent?: SoldSectionAdVariant;
  sections?: string[];
  stampDutyValue?: string;
};

//optional params as part of a listing only on Sold section
export enum SoldSectionAdVariant {
  LIMITED = 'LIMITED',
  RESTRICTED = 'RESTRICTED',
}

// TODO: Make this more accurate
export type Development = {
  auctionInfo?: {
    date: Date;
    address: string;
  };
  description: string;
  facilities?: {
    key: string;
    name: string;
  }[];
  featuredLevel?: AdFeatureLevel;
  propertySize?: string;
  id: number;
  media: Media;
  numBedrooms?: string;
  numBathrooms?: string;
  openViewings?: OpenViewings;
  price: string;
  priceHistory?: PriceHistoryIndicator;
  propertyTypeExtraInfo: string;
  propertyOverview?: { label: string; text: string }[];
  sellingType?: string;
  propertyType: string;
  taxDesignation: string;
  title: string;
  ber?: BER;
  features: string[];
  lastUpdateDate: string;
  seller: Seller;
  point: Point;
  seoFriendlyPath: string;
};

export type Point = {
  type: string;
  coordinates: number[];
};

export type Seller = {
  alternativePhone?: string;
  phone?: string;
  standardLogo?: string;
  squareLogo?: string;
  name?: string;
  backgroundColour?: string;
  profileImage?: string;
  branch?: string;
  sellerType?: SellerType;
  licenceNumber?: string;
  showContactForm?: boolean;
  phoneWhenToCall?: string;
  address?: string;
  sellerId?: number;
  premierPartnerSeller?: boolean;
  email?: string;
};

export enum SellerType {
  PRIVATE = 'PRIVATE_USER',
  UNBRANDED = 'UNBRANDED_AGENT',
  BRANDED = 'BRANDED_AGENT',
}

export interface AgentUser {
  access_token: string;
  agent_id: string;
  legacy_agent_id?: string;
  agent_name: string;
  email_verified: boolean;
  expires_at: number;
  given_name: string;
  id_token: string;
  name: string;
  preferred_username: string;
  sub: string;
  user_id: string;
  username: string;
  sms_verified?: boolean;
  impersonator?: { username: string };
}

export interface PrivateUser {
  access_token: string;
  email: string;
  email_verified: boolean;
  expires_at: number;
  given_name: string;
  id_token: string;
  name: string;
  preferred_username: string;
  sub: string;
  username: string;
  user_details?: {
    consent: {
      termsOfUseAccepted: boolean;
      receiveEmailAccepted: boolean;
      receiveNotificationAccepted: boolean;
    };
    contactInfo: {
      phone?: string;
      phoneInfo: string;
      email: string;
      altEmail: string;
    };
    createdAt: number;
    description: string;
    email: string;
    legacyId: number;
    name: string;
    origin: string;
    segments?: string[];
    updatedAt: number;
    userAttributes?: {
      key: string;
      value: string;
    }[];
    userId: number;
    username: string;
    verified: boolean;
  };
  user_id: string;
  sms_verified?: boolean;
  impersonator?: { username: string };
}

export interface FullKeycloakUser extends PrivateUser {
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
}

export type TokenSet = {
  access_token: string;
  expires_at: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
  claims: () => {
    sms_verified: boolean;
    impersonator: { username: string };
  };
};

export type MapSearchListing = {
  listing: Property;
  savedAd?: boolean;
};

export type SearchPropertyCard = {
  listing: Listing;
  seller?: Seller;
  savedAd?: boolean;
};

export type SearchPropertyCards = {
  listings: [SearchPropertyCard];
  paging: [Paging];
};

export interface NextContext {
  pathname: string;
  query: any;
  asPath: string;
  resolvedUrl: string;
  req: {
    locale: string;
    localeDataScript: string;
    messages: object;
    antdLocale: object;
    user?: PrivateUser | AgentUser;
    headers?: string;
    query?: {
      from?: number;
      verified?: boolean;
    };
    originalUrl?: string;
  };
  res?: any;
  err?: any;
  renderPage: (cb: Function) => object;
}

export type NavLinks = {
  name: string;
  url?: string;
  openInNew?: boolean;
  className?: string;
  onClick?: () => void;
}[];

/**
 * Shared URL path chunks for the SRP.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum SEARCH_URLS {
  BUY = 'property-for-sale',
  RENT = 'property-for-rent',
  SHARE = 'sharing',
  SOLD = 'sold-properties',
  RECENT = 'recent',
  NEW_HOMES = 'new-homes-for-sale',
  COMMERCIAL_RENT = 'commercial-properties-for-rent',
  COMMERCIAL_BUY = 'commercial-properties-for-sale',
  STUDENT_ACCOMMODATION_RENT = 'student-accommodation-for-rent',
  STUDENT_ACCOMMODATION_SHARE = 'student-accommodation-to-share',
  PARKING_BUY = 'parking-spaces-for-sale',
  PARKING_RENT = 'parking-spaces-for-rent',
  HOLIDAY_HOMES = 'holiday-homes', //Deprecated
}

/**
 * Shared URL path chunks for the Valuation Tool.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum VT_SEARCH_URLS {
  PROPERTY_PRICE_REGISTER = 'valuation-tool-property-price-register',
  RESIDENTIAL_RENT = 'valuation-tool-residential-rent',
  RESIDENTIAL_SALES = 'valuation-tool-residential-sales',
  COMMERCIAL = 'valuation-tool-commercial',
}

export enum VT_SEARCH_URLS_V2 {
  PROPERTY_PRICE_REGISTER = 'valuation-tool-v2-property-price-register',
  RESIDENTIAL_RENT = 'valuation-tool-v2-residential-rent',
  RESIDENTIAL_SALES = 'valuation-tool-v2-residential-sales',
  COMMERCIAL = 'valuation-tool-v2-commercial',
}

export enum AGENT_OFFERS {
  RESIDENTIAL_SALES = 'offers/residential-for-sale',
  COMMERCIAL_SALES = 'offers/commercial-for-sale',
}

/**
 * Shared URL path chunks for the PDP.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum LISTING_PAGE_BASE_URLS {
  BUY = 'for-sale',
  RENT = 'for-rent',
  SHARE = 'share',
  SOLD = 'sold',
  RECENT = 'recent',
  NEW_HOMES = 'new-home-for-sale',
  COMMERCIAL_RENT = 'commercial-property-for-rent',
  COMMERCIAL_BUY = 'commercial-property-for-sale',
  PARKING_BUY = 'parking-space-for-sale',
  PARKING_RENT = 'parking-space-for-rent',
  HOLIDAY_HOMES = 'holiday-home', //Deprecated
}

export enum VT_LISTING_PAGE_BASE_URLS {
  PROPERTY_PRICE_REGISTER = 'valuation-tool-property-price-register-property',
  RESIDENTIAL_RENT = 'valuation-tool-residential-rent-property',
  RESIDENTIAL_SALES = 'valuation-tool-residential-sales-property',
  COMMERCIAL = 'valuation-tool-commercial-property',
}

/**
 * Shared URL path chunks for the Homepage.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum HOMEPAGE_URLS {
  BUY = '',
  RENT = 'homes-to-rent',
  SHARE = 'rooms-to-rent',
  SOLD = 'sold-properties',
  NEW_HOMES = 'new-build',
  SELL = 'https://www.sell.daft.ie/',
  COMMERCIAL = 'commercial-property',
  STUDENT_ACCOMMODATION = 'student-accommodation',
}

/**
 * Shared next.js page names.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum PAGES {
  HOME = 'index',
  SEARCH = 'search',
  LISTING = 'property',
  MY_DAFT = 'mydaft',
  VT_SEARCH = 'valuation-tool',
}

/**
 * Shared URL path chunks for our various Keycloak realms.
 *
 * If you change this, please read the notes in the next.config about the manual copy of this object
 * we've made in there.
 */
export enum REALM_URLS {
  DAFT = '',
  AGENTS = '/agents',
  AGENTSV2 = '/agent-v2',
}

export type AreaGrouping = {
  counties: Area[];
  cities: Area[];
  colleges: Area[];
  areas: Area[];
};

export type Area = {
  displayName: string;
  displayValue: string;
  id: string;
};

export type AreaTag = {
  displayName: string;
  displayValue: string;
  id: string;
  propertyCount: PropertyCounts;
};

export type PropertyCounts = {
  residentialForSale: number;
  residentialForRent: number;
  sharing: number;
  commercialForSale: number;
  commercialToRent: number;
  studentAccommodationForRent: number;
  studentAccommodationToShare: number;
  parkingForSale: number;
  parkingToRent: number;
  newHomes: number;
  holidayHomes: number;
};

export enum SECTIONS {
  BUY = 'residential-for-sale',
  RENT = 'residential-to-rent',
  SHARE = 'sharing',
  SOLD = 'residential-sold',
  RECENT = 'recent',
  COMMERCIAL_BUY = 'commercial-for-sale',
  COMMERCIAL_RENT = 'commercial-to-rent',
  STUDENT_ACCOMMODATION_RENT = 'student-accommodation-for-rent',
  STUDENT_ACCOMMODATION_SHARE = 'student-accommodation-to-share',
  PARKING_BUY = 'parking-for-sale',
  PARKING_RENT = 'parking-to-rent',
  NEW_HOMES = 'new-homes',
  HOLIDAY_HOMES = 'holiday-homes',
  VT_PROPERTY_PRICE_REGISTER = 'valuation-tool-property-price-register',
  VT_RESIDENTIAL_RENT = 'valuation-tool-residential-rent',
  VT_RESIDENTIAL_SALES = 'valuation-tool-residential-sales',
  VT_COMMERCIAL = 'valuation-tool-commercial',
}

export type CategoryName =
  | 'COMMERCIAL'
  | 'RESIDENTIAL'
  | 'STUDENT_ACCOMMODATION';

export type Breadcrumb = {
  displayValue: string;
  /**
   * Comes down as relative path
   */
  url: string;
};

export type Breadcrumbs = Breadcrumb[];

export type LocationInputBoxOptions = {
  initWithFocus?: boolean;
  shouldShowOnDesktop?: boolean;
  selectedSection?: SECTIONS;
  shouldShowAreaCounts?: boolean;
  parentRef?: React.RefObject<HTMLElement>;
  currentPath: string;
};

export type FilterOptions = {
  LocationInputBox?: LocationInputBoxOptions;
};

export interface MapProps {
  viewport: {
    latitude: number;
    longitude: number;
    width: number;
    height: number;
    zoom: number;
  };
  marker?: {
    latitude: number;
    longitude: number;
  };
  markerDraggable?: boolean;
  markerOnDragEnd?: (event: any) => void;
  onClickCallback?: (event: any) => void;
  recenterButton?: boolean;
  testId?: string;
  CDN_URL_STATIC_DIRECTORY: string;
  markerOffsetTop?: number;
  markerOffsetLeft?: number;
}

export type GalleryModalProps = {
  dfpDisabled?: boolean;
  images: Photo[];
  reactDFPAdsReadyToLoad: boolean;
  seller?: Seller;
  totalImages: number;
  /**
   * This will prefix any data-tracking within this component.
   */
  trackingAttrPrefix: string;
};

export type GridGalleryProps = {
  imageList: CaptionedPhoto[];
  dataTestId: string;
};

export type UnitStatus = {
  name: string;
  type: string;
};

// mydaft types
export type PaginationEvent = {
  filterType: string;
  from: number;
  pageSize: number;
  searchQueryGroup: string;
  startTime?: number;
};

export type Alerts = {
  developmentAlert?: boolean;
  priceChangeAlert: boolean;
};

//TODO: Remove this type when we have a proper AdDetailsGateway compatible type
export type LegacyListing = {
  listing: Property;
  alerts: Alerts;
  paging: Paging;
};

export type SavedSearchType = {
  search: {
    details: string;
    id: number;
    title: string;
    filters: string;
    numberOfFilters: string;
    notificationFrequency: string;
    fromLegacy: boolean;
    request: SearchRequest;
  };
};

export enum SemanticButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum NewButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  GHOST = 'GHOST',
}

export enum NewButtonSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type CustomExpressRequest = {
  [key: string]: any;
} & express.Request;

export type BuyingToolsProps = {
  document: Document;
};

export type ValuationToolTableConfig = {
  propertyBaseUrl: string;
  useSpId: boolean;
  tableItems: {
    headerText: string;
    colSpan?: number;
    key: string;
    testId: string;
  }[];
};

export type HomepageSEOProps = {
  [path: string]: {
    title: string;
    tagline: string;
    metaTitle: string;
    metaDescription: string;
    ogImage: string;
    backgroundImage: {
      xl?: string;
      desktop: string;
      mobile: string;
    };
  };
};

type BuyingCost = {
  title: string | null;
  items: {
    label: string;
    value: string;
    info?: string;
    parts?: BuyingCostItem[];
  }[];
};

export type BuyingCostItem = {
  label: string;
  value: string;
  info?: string;
};

export type BuyingBudgetPartner = 'MORTGAGE_LINE' | 'LOW_QUOTES';

export type ResultItem = {
  key: string;
  label: string;
  value?: string;
  link?: string | null;
  linkLabel?: string | null;
  tips?: string[] | null;
  items?: ResultItem[] | null;
};

type BuyingBudgetResultSetItem = {
  timeline: string;
  results?: {
    title: string;
    items: ResultItem[];
  };
};

export type BuyingBudgetResultSets = {
  type: string;
  label: string;
  results: BuyingBudgetResultSetItem[] | null;
};

export type SavingPlansResultSets = {
  label: string;
  type: string;
  results: {
    title: string;
    items?: ResultItem[];
    savingsGoal?: number;
  };
};

export type BuyingBudgetResponse = {
  buyingBudgetId?: number | null;
  buyingBudgetVersion?: number | null;
  buyingBudget?: number | null;
  mortgage?: number | null;
  totalDeposit?: number | null;
  userRawDeposit?: number | null;
  buyingCost?: BuyingCost | null;
  tips?: string[] | null;
  isLowDepositLimitingMortgage?: boolean | null;
  isDepositIncludeHTB?: boolean | null;
  partners: BuyingBudgetPartner[];
  lastUpdateDate?: string | null;
  buyingBudgetResultSets?: BuyingBudgetResultSets[];
  savingPlans?: SavingPlansResultSets[];
  displayValuationRequest: boolean;
  displayReceiveUpdates: boolean;
};

export type NextSteps = {
  mortgagePartner: string;
  steps: { text: string; icon: string }[];
};

export type OnAddressFoundType = {
  result: {
    code: number;
    text: string;
  };
  isUniqueAddress: boolean;
  postcode: string;
  addressId: number;
  addressType: {
    code: number;
    text: string;
  };
  matchLevel: {
    code: number;
    text: string;
  };
  postalAddress: string[];
  vanityAddress: string[];
  reformattedAddressResult: {
    code: number;
    text: string;
  };
  reformattedAddress: string[];
  cleanResult: {
    isSpellingChanged: boolean;
    isFormatChanged: boolean;
    isAltered: boolean;
  };
  totalOptions: number;
  options: [];
  input: {
    key: string;
    txn: string;
    address: string;
    language: string;
    country: string;
    limit: number;
    geographicAddress: boolean;
    vanityMode: boolean;
    addressElements: boolean;
    addressProfileName: string;
    __reserved: {
      originator: string;
      postcode: string;
      postcodeStatus: string;
    };
  };
  links: [
    {
      rel: string;
      href: string;
    },
  ];
};

export type EircodeAddressType = {
  address: string;
  postcode: string;
};

export type AutoAddressEircodeInputType = {
  name: string;
  currentValue: string | undefined;
  eircodeAddress: EircodeAddressType | null;
  setEircodeAddress: Dispatch<SetStateAction<EircodeAddressType | null>>;
};

export enum BidderStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export type Bidder = {
  bidderId: number;
  userId: number;
  firstName: string;
  lastName: string;
  alias: string;
  phone: string;
  status: BidderStatus;
  email: string;
  mortgageApproved: boolean;
  firstTimeBuyer: boolean;
  mover: boolean;
  cashBuyer: boolean;
  createDate: number;
  offersCount: number;
  isMe?: boolean;
};

export enum OfferStatus {
  APPROVED = 'APPROVED',
  WITHDRAWN = 'WITHDRAWN',
  WON = 'WON',
}

export type Offer = {
  offerId: number;
  bidderId: number;
  amount: number;
  status: OfferStatus;
  conditions: string;
  createDate: number;
};

export type OffersBiddersDetails = {
  offers: Offer[];
  bidders: Bidder[];
  status: number;
  minimumIncrement: number;
  sendLosingEmail: boolean;
  sendWinningEmail: boolean;
  showOfferHistorySaleAgreed: boolean;
  sendVendorEmail: boolean;
  vendorEmail?: string;
  vendorName?: string;
  makeOfferPrivate: boolean;
};

export type ConfigureOffersSubmission = {
  sendWinningEmail: boolean;
  sendLosingEmail: boolean;
  showOfferHistorySaleAgreed: boolean;
  makeOfferPrivate: boolean;
  sendVendorEmail: boolean;
  vendorName: string;
  vendorEmail: string;
  awaitingBidders: number;
  bookingDeposit: number;
  minimumIncrement: string;
  minimumOfferAmount: string;
  offersCount: number;
  status: string;
};

export type RegisterToBidQuery = {
  firstName: string;
  lastName: string;
  phone: number;
  email: string;
  buyerType?: {
    firstTimeBuyer?: boolean;
    mover?: boolean;
  };
  purchaseType?: {
    mortgageApproved?: boolean;
    cashBuyer?: boolean;
  };
};

export type RegisterToBidForm = {
  firstName: string;
  lastName: string;
  phone: number;
  email: string;
  buyerType: 'firstTimeBuyer' | 'mover';
  purchaseType: 'mortgageApproved' | 'cashBuyer';
};
export interface FormSubmission {
  amount: number;
  condition?: string;
}

export interface SubmitOfferQuery extends FormSubmission {
  adId: number;
}

export type Dashboard = {
  url: string;
  label: string;
  section: string;
  query: string;
};

export type TableauDashboardPageProps = {
  dashboard: Dashboard;
  dfpTargetingValues: { [key: string]: string };
  section: string;
  status: number;
  user: PrivateUser | AgentUser;
  agentType?: string;
};

export type CustomBlock = {
  adType: string;
  timeRemaining: { text: string; color: string };
};

export type ParkingCustomBlock = {
  parkingAccess: string;
  capacity: string;
};

export type PaymentText = {
  adState: string;
  text: JSX.Element;
  color: string;
};

export enum Orientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}
export enum EndpointImageSizes {
  SIZE_1440_960 = 'mediaSizes=size1440x960',
  SIZE_1200_1200 = 'mediaSizes=size1200x1200',
  SIZE_720_480 = 'mediaSizes=size720x480',
  SIZE_360_240 = 'mediaSizes=size360x240',
  SIZE_72_52 = 'mediaSizes=size72x52',
}
